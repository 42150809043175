import React, { useState } from "react";
import "./Admin.css";

export default function Admin() {
  const [payer, setPayer] = useState<number>(-1);
  const [price, setPrice] = useState<number>(0);
  const [location, setLocation] = useState<string>();
  const [image, setImage] = useState<any>();

  const [reqResponse, setResponse] = useState<any>();

  const getCosts = () => {
    fetch("api/cost.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        // console.log(myJson);
        calcCosts(myJson);
      });
  };

  function calcCosts(i: any) {
    if (payer != -1) {
      console.log(payer);
      console.log(price);

      let curCosts: any = i;
      console.log(curCosts);

      curCosts[payer] = curCosts[payer] - (price - price / 4);
      for (let index = 0; index < 4; index++) {
        if (index != payer) {
          curCosts[index] = curCosts[index] + price / 4;
        }
      }

      console.log(curCosts);

      setResponse("Kosten aktualisieren...");
      fetch(
        "https://trip.jo-dev.de/api/cost.php?i=" + JSON.stringify(curCosts)
      ).then((response) => {
        console.log(response);
        setResponse("Kosten aktualisiert!");
        setTimeout(() => setResponse(""), 3000);
      });
    }
  }

  function uploadLocation() {
    setResponse("Hochladen...");
    fetch("https://trip.jo-dev.de/api/location.php?loc=" + location).then(
      (response) => {
        console.log(response);
        setResponse("Location hochgeladen!");
        setTimeout(() => setResponse(""), 3000);
      }
    );
  }

  function uploadImage() {
    setResponse("Hochladen...");
    var date = new Date().toISOString();

    var blob = image.slice(0, image.size, "image/jpg");
    var datename = new File([blob], date + ".jpg", {
      type: "image/jpg",
    });

    const data = new FormData();
    data.append("fileToUpload", datename);
    fetch("https://trip.jo-dev.de/api/image.php", {
      method: "POST",
      body: data,
    }).then((response) => {
      console.log(response);
      setResponse("Foto hochgeladen!");
      setTimeout(() => setResponse(""), 3000);
    });
  }

  return (
    <div className="adminContainer">
      <div className="adminTitle">ADMIN PANEL</div>
      <div className="adminContent">
        <div className="adminCost">
          <p className="adminHeader">Kosten</p>
          <input
            type={"tel"}
            placeholder="Preis"
            onChange={(e) => setPrice(Number(e.target.value))}
          />
          <select
            name="Gezahlt"
            onChange={(e) => setPayer(Number(e.target.value))}
          >
            <option value={"-1"}>Bitte Auswählen</option>
            <option value={"0"}>Anna</option>
            <option value={"1"}>Liv</option>
            <option value={"2"}>Dave</option>
            <option value={"3"}>Jojo</option>
          </select>
          <button onClick={() => getCosts()}>Speichern</button>
        </div>
        <div className="adminLocation">
          <p className="adminHeader">Aktueller Ort</p>
          <input
            type={"text"}
            placeholder={"Ortsname"}
            onChange={(e) => setLocation(e.target.value)}
          />
          <button onClick={() => uploadLocation()}>Speichern</button>
        </div>
        <div className="adminSelfie">
          <p className="adminHeader">Fotos</p>
          <input
            type={"file"}
            accept={".jpg"}
            onChange={(e) => {
              setImage(e.target.files![0]);
            }}
          />
          <button onClick={() => uploadImage()}>Speichern</button>
          <p>{reqResponse}</p>
        </div>
      </div>
    </div>
  );
}
